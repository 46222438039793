import { detectAlpha2Country } from "@src/utils/country"

const axios = require("axios")
const DICTIONARY_API_URL = process.env.GATSBY_DICTIONARY_API_URL

export const getKeys = (texts = {}) => {
  return axios(DICTIONARY_API_URL)
    .then(res => {
      let dictionary = res.data["data"]["content"]
      console.debug("[Registry] Set dictionary values...")
      let contry = dictionary[detectAlpha2Country()]
      let translations = {}
      for (const [key, value] of Object.entries(texts)) {
        if (contry)
          translations[key] = contry[value]
            ? contry[value]
            : `[Dictionary]: key ${value} not found.`
        else
          translations[
            key
          ] = `[Dictionary]: in the cms not found country ${detectAlpha2Country()}`
      }
      return translations
    })
    .catch(() => {
      let translations = {}
      for (const [key, value] of Object.entries(texts)) {
        translations[key] = "[Dictionary]: not loaded."
      }
      return translations
    })
}
