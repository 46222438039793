import React, { Fragment, useState, useEffect } from "react"
import _ from "lodash"
import { Box, Grid, makeStyles } from "@material-ui/core"

import { useTranslation } from "react-i18next"
import Text from "../components/Text"
import Image from "../components/Image"
import DeaError from "../components/DeaError"
import FluidImage from "../components/FluidImage"
import { showGuides } from "@src/utils/debug"

import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
// import Select from '@material-ui/core/Select';
import { detectAlpha2Country } from "@src/utils/country"
import Select from "../components/Select"
import { GTMEvent } from "../utils/gtm"
import TrustBox from "../components/TrustBox"
import ChooseCountryOverlay from "../components/ChooseCountryOverlay"
import axios from "axios"
import { getKeys } from "@src/utils/dictionary"

const useStyles = makeStyles(theme => ({
  footerContainer: {
    order: showGuides("4px solid blue"), // FIX ** guides
    //marginTop: theme.spacing(4), // FIX ** apply margin top as default theme
    backgroundColor: theme.customPalette["900"],
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      //marginTop: theme.spacing(0),
    },
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(5),
    },
  },
  logo: {
    height: "71px",
    maxWidth: "228px",
  },
  socialContainer: {
    padding: theme.spacing(2, 0),
    paddingLeft: "2vw",
    paddingRight: "2vw",
    boxSizing: "border-box",
    borderStyle: "solid",
    borderColor: theme.customPalette.charcoal,
    borderWidth: "0 0 1px 0",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      borderWidth: 0,
    },
  },
  socialTitleContainer: {
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  socialLinkContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 0, 3, 0),
      boxSizing: "border-box",
      borderStyle: "solid",
      marginTop: -1,
      borderColor: theme.customPalette.charcoal,
    },
    "&:nth-child(odd)": {
      [theme.breakpoints.down("sm")]: {
        borderWidth: "1px 0 1px 1px",
      },
    },
    "&:nth-child(even)": {
      [theme.breakpoints.down("sm")]: {
        borderWidth: "1px 0 1px 0",
      },
    },
  },
  iconLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  linkListContainer: {
    padding: theme.spacing(5, 0),
    paddingLeft: "2vw",
    paddingRight: "2vw",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0, 4, 0),
    },
  },
  linkList: {},
  linksTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  link: {
    width: "100%",
    textDecoration: "none",
    color: "#b9b9b9 !important",
    "&:visited": {
      color: "#b9b9b9 !important",
    },
  },
  collectionLinksContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  collectionChunkContainer: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  socialIcon: {
    display: "flex",
    maxHeight: 38,
    width: 74,
    justifyContent: "center",
  },
  borderLeft: {
    borderStyle: "solid",
    borderColor: theme.customPalette.charcoal,
    borderWidth: "0 0 0 1px",
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      borderWidth: 0,
    },
  },
  paymentsContainer: {
    display: "flex",
    justifyContent: "center",
    borderStyle: "solid",
    borderColor: theme.customPalette.charcoal,
    borderWidth: "1px 0 0 0",
    padding: theme.spacing(4, 11),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 3),
    },
  },
  paymentElement: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0, 1),
  },
  paymentIcon: {
    height: 32,
    width: 48,
  },
  securePaymentContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
      justifyContent: "center",
      alignItems: "center",
    },
  },
  securePaymentContainerBored: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down("lg")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  securePaymentText: {
    whiteSpace: "pre-line",
    paddingLeft: theme.spacing(1),
    textAlign: "left",
    lineHeight: "1.3 !important",
  },
  chooseCountryGrid: {
    [theme.breakpoints.up("sm")]: {
      // marginLeft: "auto",
    },
  },
  formControl: {
    width: "100%",
    // background: theme.customPalette.charcoal,
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.customPalette.greyLight,
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
      "& .MuiFilledInput-root": {
        height: "42px",
      },
    },
  },
  select: {
    background: theme.customPalette.charcoal,
    width: theme.spacing(25),
    color: "#fff",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiInput-formControl": {
      borderBottom: `3px solid ${theme.customPalette.tangerine}`,
    },
    "& .MuiInput-formControl:focus": {
      borderBottom: `3px solid ${theme.customPalette.tangerine}`,
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      paddingLeft: "8px",
    },
    "& option": {
      background: `${theme.customPalette.charcoal} !important`,
      color: "#fff",
    },
  },
  label: {
    color: theme.customPalette.greyLight,
    textTransform: "uppercase",
    paddingLeft: "9px",
    paddingTop: "4px",
    fontSize: "10px",
    zIndex: 1,
  },
  copyrightContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 0, 2, 0),
    [theme.breakpoints.only("xs")]: {
      display: "block",
      textAlign: "center",
    },
  },
  copyrightElement: {
    padding: theme.spacing(0, 4),
    [theme.breakpoints.only("xs")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  paymentSection: {
    flexWrap: "wrap",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  imBoredClass: {
    flexGrow: 1,
  },
}))

const FooterModule = ({
  footerBackgroundImage,
  socialText,
  socialLinks,
  links,
  collectionText,
  collectionLinks,
  securePayment,
  paymentIcons,
  copyrightText,
  copyrightLinks,
}) => {
  const classes = useStyles()
  const [showOverlay, setShowOverlay] = useState(false)
  var [country, setCountry] = useState("")

  const { t, i18n } = useTranslation()
  const [allCountries, setAllCountries] = useState()

  const changeCountry = e => {
    GTMEvent("footer", "language", e.target.value)
    setCountry(e.target.value)
    window.location.href = e.target.value
  }

  useEffect(() => {
    setCountry(`https://www.fanhome.com/${detectAlpha2Country()}/`)
  })

  useEffect(async () => {
    var resultCountries
    await axios
      .get(`${process.env.GATSBY_COUNTRIES_URL}`)
      .then(res => {
        resultCountries = res?.data?.data
        setAllCountries(resultCountries)
      })
      .catch(err => console.log("all countries get error", err))
  }, [])

  useEffect(() => {
    async function fetchData() {
      await getKeys({
        cartTitle: "sdk.cart.title",
        cartNoItems: "sdk.cart.noitems",
        cartCtaLabel: "sdk.cart.cta.label",
        cartRemoveItemTitle: "sdk.cart.modal.removeitem.title",
        cartRemoveItemMessage: "sdk.cart.modal.removeitem.message",
        cartRemoveItemAllow: "sdk.cart.modal.removeitem.allow",
        cartRemoveItemDisallow: "sdk.cart.modal.removeitem.disallow",
      }).then(res => {
        window.deaCommerce = {
          cart: {
            title: res?.cartTitle,
            noitems: res?.cartNoItems,
            cta: {
              label: res?.cartCtaLabel,
            },
            modal: {
              removeitem: {
                title: res?.cartRemoveItemTitle,
                message: res?.cartRemoveItemMessage,
                allow: res?.cartRemoveItemAllow,
                disallow: res?.cartRemoveItemDisallow,
              },
            },
          },
        }

        const script = document.createElement("script")
        script.src = "/cart.js"
        script.async = true
        document.body.appendChild(script)
      })
    }
    fetchData()
  }, [])

  try {
    return (
      <footer className={classes.footerContainer}>
        <Box position="relative">
          <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.socialContainer}
          >
            <Grid
              item
              xs={12}
              md={3 + (9 % socialLinks.length)}
              className={classes.socialTitleContainer}
            >
              <Text
                variant="titleXXL"
                component="div"
                align="center"
                color="textTertiary"
              >
                {socialText}
              </Text>
            </Grid>
            {(socialLinks || []).map((socialLink, i) => (
              <Fragment key={`social_link_${i}`}>
                <Grid
                  className={classes.socialLinkContainer}
                  xs={socialLinks.length % 2 === 0 ? 6 : 4}
                  md={Math.floor(9 / socialLinks.length)}
                  item
                >
                  <a {...socialLink.link} className={classes.iconLink}>
                    <FluidImage
                      {...socialLink.icon}
                      alt={socialLink.link ? socialLink.link.title : ""}
                      className={classes.socialIcon}
                    ></FluidImage>
                  </a>
                </Grid>
                {/* add padding */}
                {(socialLinks || []).length % 2 === 1 && i % 2 === 1 && (
                  <Grid
                    key={`hidden_${i}`}
                    className={classes.socialLinkContainer}
                    xs={false}
                    item
                  ></Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        </Box>
        <Box position="relative">
          <Grid
            container
            alignContent="center"
            className={classes.linkListContainer}
          >
            <Grid item xs={12} md={3} className={classes.logoContainer}>
              <Image className={classes.logo} name="logo_white" />
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid container>
                {(links || []).map((link, i) => (
                  <Grid
                    key={`link_${i}`}
                    className={classes.linkContainer}
                    xs={12}
                    item
                  >
                    <a className={classes.link} {...link}>
                      <Text variant="bodyS">{link.title}</Text>
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container className={classes.borderLeft}>
                <Grid className={classes.linksTitle} item xs={12}>
                  {" "}
                  <Text variant="bodyM" color="textTertiary" weigth="bold">
                    {collectionText}
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <Grid className={classes.collectionLinksContainer} container>
                    {_.chunk(collectionLinks || [], 3).map((chunk, i) => (
                      <Grid xs={12} md={true} item>
                        <Grid
                          className={classes.collectionChunkContainer}
                          container
                        >
                          {chunk.map((link, j) => (
                            <Grid
                              key={`collection_link_${j}`}
                              className={classes.linkContainer}
                              xs={12}
                              item
                            >
                              <a className={classes.link} {...link}>
                                <Text variant="bodyS">{link.title}</Text>
                              </a>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.paymentsContainer}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={3}>
              <Grid container className={classes.paymentSection}>
                {(paymentIcons || []).map((icon, i) => (
                  <Grid
                    key={`payment_icon_${i}`}
                    item
                    className={classes.paymentElement}
                  >
                    <FluidImage
                      className={classes.paymentIcon}
                      {...icon}
                    ></FluidImage>
                  </Grid>
                ))}
                <Grid item xs={12} md={"auto"} className={classes.imBoredClass}>
                  {securePayment && (
                    <Box
                      className={classes.securePaymentContainerBored}
                      color="#b9b9b9"
                    >
                      <FluidImage
                        className={classes.paymentIcon}
                        {...securePayment.icon}
                      ></FluidImage>
                      {securePayment.text && (
                        <Text
                          className={classes.securePaymentText}
                          variant="bodyXS"
                        >
                          {securePayment.text}
                        </Text>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md="auto">
              <TrustBox type="mini" country={detectAlpha2Country()}></TrustBox>
            </Grid>
            <Grid item xs={12} md={3} className={classes.chooseCountryGrid}>
              <Box
                className={classes.securePaymentContainer}
                justifyContent="flex-end"
              >
                <FormControl className={classes.formControl}>
                  <InputLabel
                    className={classes.label}
                    htmlFor="choose-country"
                  >
                    {t("chooseCountry")}
                  </InputLabel>
                  <Select
                    native
                    inputProps={{
                      name: "country",
                      id: "choose-country",
                    }}
                    className={classes.select}
                    value={country}
                    onClick={() => setShowOverlay(true)}
                    style={{ overflow: "hidden" }}
                  >
                    <div aria-label="None" value="">
                      {
                        allCountries?.find(
                          country =>
                            country.code.toLowerCase() === detectAlpha2Country()
                        )?.name
                      }
                    </div>
                    <option value="/" hidden>
                      {
                        allCountries?.find(
                          country =>
                            country.code.toLowerCase() === detectAlpha2Country()
                        )?.name
                      }
                    </option>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ChooseCountryOverlay
          open={showOverlay}
          onClose={() => setShowOverlay(false)}
          countries={allCountries}
        />
        <Box className={classes.copyrightContainer} bgcolor="#2e2e2e">
          {copyrightText && (
            <Box
              className={classes.copyrightElement}
              fontWeight="bold"
              color="#fff"
            >
              <Text variant="bodyXS">{copyrightText}</Text>
            </Box>
          )}
          {(copyrightLinks || [])
            .filter(l => l && l.href && l.title)
            .map((link, i) => (
              <Box
                key={`copyright_link_${i}`}
                className={classes.copyrightElement}
                fontWeight="bold"
                color="white"
              >
                <a {...link} className={classes.link}>
                  <Text variant="bodyXS">{link.title}</Text>
                </a>
              </Box>
            ))}
        </Box>
      </footer>
    )
  } catch (err) {
    return (
      <DeaError error={err} parentModule={{ defaultProps: defaultProps }} />
    )
  }
}

const defaultProps = {
  footerBackgroundImage: "",
  linkLists: [],
  socialText: "",
  socialLinks: [],
  paymentText: "",
  paymentIcons: [],
  copyrightText: "",
  copyrightLinks: [],
}

FooterModule.defaultProps = defaultProps

export default FooterModule
